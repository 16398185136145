
import React, { useState, useEffect } from 'react';
import { saveAs } from 'file-saver';
import {
    Document,
    Packer,
    Paragraph,
    TextRun,
    HeadingLevel,
    AlignmentType,
    Table,
    TableCell,
    TableRow,
    WidthType,
    BorderStyle,
} from 'docx';
import './Form.css';

const jsonData = {
    "Name of Responding Organization": "American Investigator",
    "Industry Sector or Public Agency": "Private agency",
    "Contact Person": "Rahul Patel",
    "Contact Email": "ronnie.dsouza@yahoo.co.in",
    "Contact Telephone": "7581060222",
    "Type of (alleged) crime (Circle one)": "TFS breach",
    "Summary of Case (include main method used or attempted to commit the ML/TF/TFS Breach)": "Rahul Patel committed a crime against TFS breach by drunken driving, hitting a lady with his car. He is facing conviction based on evidence found at his home.",
    "ML Stage (circle one)": "Not specified",
    "Case Results": "Rahul Patel will have to pay around $100,000 as compensation or face 3 years in jail.",
    "Main factor(s) that led to detection": "The drunken driving incident where he hit a lady and the evidence found at his home.",
    "Other Red Flags and High Risk Indicators (e.g. PEPs)": "Drunken driving, evidence found at home.",
    "Suspected Predicate Offence(s)": "Drunken driving",
    "Type of subject(s)": "Rahul Patel",
    "Type of account(s) misused": "Not specified",
    "Other countries involved (explain)": "Crime committed in America by an Indian individual.",
    "Amount/Value involved": "$100,000",
    "Nominee shareholder": "Not specified",
    "Nominee director": "Not specified",
    "Corporate Director": "Not specified",
    "Politically exposed persons": "Not specified",
    "Link with High Risk Jurisdiction": "Not specified",
    "Complex structure": "Not specified",
    "Shell/shelf/dormant company": "Not specified",
    "High Risk industry": "Not specified"
};

function Form({ data }) {
    const [formData, setFormData] = useState({});
    console.log(data);

    useEffect(() => {
        setFormData(data);
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleDownload = async () => {
        if (!formData) return;

        const doc = new Document({
            styles: {
                paragraphStyles: [
                    {
                        id: "body",
                        name: "Body",
                        basedOn: "Normal",
                        next: "Normal",
                        quickFormat: true,
                        run: {
                            size: 24,
                            font: "Arial",
                        },
                        paragraph: {
                            spacing: {
                                after: 200,
                            },
                        },
                    },
                ],
            },
            numbering: {
                config: [
                    {
                        reference: "numberedList",
                        levels: [
                            {
                                level: 0,
                                format: "decimal",
                                text: "%1.",
                                alignment: AlignmentType.START,
                                style: {
                                    paragraph: {
                                        indent: { left: 720, hanging: 360 },
                                    },
                                },
                            },
                        ],
                    },
                ],
            },
            sections: [
                {
                    properties: {},
                    children: [
                        new Paragraph({
                            text: "Typologies Research Questionnaire",
                            heading: HeadingLevel.HEADING_1,
                            alignment: AlignmentType.CENTER,
                        }),
                        new Paragraph({
                            text: "Instructions",
                            heading: HeadingLevel.HEADING_2,
                            alignment: AlignmentType.LEFT,
                        }),
                        new Paragraph({
                            text: "All responses will remain confidential, submitting organizations will only be seen by the Typologies research team. Include contact information in case writers have additional questions.",
                            style: "body",
                            numbering: {
                                reference: "numberedList",
                                level: 0,
                            },
                        }),
                        new Paragraph({
                            text: "Fill in 1 sheet per case.",
                            style: "body",
                            numbering: {
                                reference: "numberedList",
                                level: 0,
                            },
                        }),
                        new Paragraph({
                            text: "Remove all identifiable information (e.g. name of subject, name of related companies). Use non-identifiable language, e.g. Subject A, Company B.",
                            style: "body",
                            numbering: {
                                reference: "numberedList",
                                level: 0,
                            },
                        }),
                        new Paragraph({
                            text: "Use simple terminology/language, avoid acronyms.",
                            style: "body",
                            numbering: {
                                reference: "numberedList",
                                level: 0,
                            },
                        }),
                        new Paragraph({
                            text: "Remove date references except the year(s).",
                            style: "body",
                            numbering: {
                                reference: "numberedList",
                                level: 0,
                            },
                        }),
                        new Table({
                            rows: [

                                ...Object.entries(formData)
                                    .filter(([key]) => !["Typologies", "Nominee shareholder", "Nominee director", "Corporate Director", "Politically exposed persons", "Link with High Risk Jurisdiction", "Complex structure", "Shell/shelf/dormant company", "High Risk industry"].includes(key))
                                    .map(([key, value]) => (
                                        new TableRow({
                                            children: [
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            children: [
                                                                new TextRun({ text: key, bold: true }),
                                                            ],
                                                        }),
                                                    ],
                                                }),
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            children: [
                                                                new TextRun({ text: value }),
                                                            ],
                                                        }),
                                                    ],
                                                }),
                                            ],
                                        })
                                    )),
                            ],
                            width: {
                                size: 100,
                                type: WidthType.PERCENTAGE,
                            },
                        }),
                        new Paragraph({
                            text: "Does this case involve any of the following typologies? Check all that apply.",
                            style: "body",
                        }),

                        new Table({
                            rows: [

                                ...["Nominee shareholder", "Nominee director", "Corporate Director", "Politically exposed persons", "Link with High Risk Jurisdiction", "Complex structure", "Shell/shelf/dormant company", "High Risk industry"].map((key) => (
                                    new TableRow({
                                        children: [
                                            new TableCell({
                                                children: [
                                                    new Paragraph({
                                                        children: [
                                                            new TextRun({ text: key, bold: true }),
                                                        ],
                                                    }),
                                                ],
                                            }),
                                            new TableCell({
                                                children: [
                                                    new Paragraph({
                                                        children: [
                                                            new TextRun({ text: formData[key] }),
                                                        ],
                                                    }),
                                                ],
                                            }),
                                        ],
                                    })
                                )),
                            ],
                            width: {
                                size: 100,
                                type: WidthType.PERCENTAGE,
                            },
                        }),
                    ],
                },
            ],
        });

        const blob = await Packer.toBlob(doc);
        saveAs(blob, 'document.docx');
    };

    return (
        <div className="container">
            <h1>Typologies Research Questionnaire</h1>
            <section className="instructions">
                <h2>Instructions</h2>
                <p>
                    All responses will remain confidential submitting organizations will only be seen by the Typologies research team. Include contact information in case writers have additional questions.
                    Fill in 1 sheet per case. Remove all identifiable information (e.g. name of subject name of related companies). Use non-identifiable language e.g. Subject A Company B.
                    Use simple terminology/language avoid acronyms. Remove date references except the year(s).
                </p>
            </section>
            <form>
                {/* Create form groups for each section */}
                <div className="form-group">
                    <label>Name of Responding Organization</label>
                    <input type="text" name="Name of Responding Organization" value={formData["Name of Responding Organization"] || ''} onChange={handleChange} />
                </div>
                <div className="form-group">
                    <label>Industry Sector or Public Agency</label>
                    <input type="text" name="Industry Sector or Public Agency" value={formData["Industry Sector or Public Agency"] || ''} onChange={handleChange} />
                </div>
                <div className="form-group">
                    <label>Contact Person</label>
                    <input type="text" name="Contact Person" value={formData["Contact Person"] || ''} onChange={handleChange} />
                </div>
                <div className="form-group">
                    <label>Contact Email</label>
                    <input type="email" name="Contact Email" value={formData["Contact Email"] || ''} onChange={handleChange} />
                </div>
                <div className="form-group">
                    <label>Contact Telephone</label>
                    <input type="tel" name="Contact Telephone" value={formData["Contact Telephone"] || ''} onChange={handleChange} />
                </div>
                <div className="form-group">
                    <label>Type of (alleged) crime (Circle one)</label>
                    <div>
                        <label><input type="radio" name="Type of (alleged) crime (Circle one)" value="ML" checked={formData["Type of (alleged) crime"] === 'ML'} onChange={handleChange} /> ML</label>
                        <label><input type="radio" name="Type of (alleged) crime (Circle one)" value="TF" checked={formData["Type of (alleged) crime"] === 'TF'} onChange={handleChange} /> TF</label>
                        <label><input type="radio" name="Type of (alleged) crime (Circle one)" value="PF" checked={formData["Type of (alleged) crime"] === 'PF'} onChange={handleChange} /> PF</label>
                        <label><input type="radio" name="Type of (alleged) crime (Circle one)" value="TFS breach" checked={formData["Type of (alleged) crime"] === 'TFS breach'} onChange={handleChange} /> TFS breach</label>
                    </div>
                </div>
                <div className="form-group">
                    <label>Summary of Case (include main method used or attempted to commit the ML/TF/TFS Breach)</label>
                    <textarea name="Summary of Case (include main method used or attempted to commit the ML/TF/TFS Breach)" value={formData["Summary of Case (include main method used or attempted to commit the ML/TF/TFS Breach)"] || ''} onChange={handleChange}></textarea>
                </div>
                <div className="form-group">
                    <label>ML Stage (circle one)</label>
                    <div>
                        <label><input type="radio" name="ML Stage (circle one)" value="Placement" checked={formData["ML Stage"] === 'Placement'} onChange={handleChange} /> Placement</label>
                        <label><input type="radio" name="ML Stage (circle one)" value="Layering" checked={formData["ML Stage "] === 'Layering'} onChange={handleChange} /> Layering</label>
                        <label><input type="radio" name="ML Stage (circle one)" value="Integration" checked={formData["ML Stage"] === 'Integration'} onChange={handleChange} /> Integration</label>
                    </div>
                </div>
                <div className="form-group">
                    <label>Case Results</label>
                    <textarea name="Case Results" value={formData["Case Results"] || ''} onChange={handleChange}></textarea>
                </div>
                <div className="form-group">
                    <label>Main factor(s) that led to detection</label>
                    <textarea name="Main factor(s) that led to detection" value={formData["Main factor(s) that led to detection"] || ''} onChange={handleChange}></textarea>
                </div>
                <div className="form-group">
                    <label>Other Red Flags and High Risk Indicators (e.g. PEPs)</label>
                    <textarea name="Other Red Flags and High Risk Indicators (e.g. PEPs)" value={formData["Other Red Flags and High Risk Indicators (e.g. PEPs)"] || ''} onChange={handleChange}></textarea>
                </div>
                <div className="form-group">
                    <label>Suspected Predicate Offence(s)</label>
                    <textarea name="Suspected Predicate Offence(s)" value={formData["Suspected Predicate Offence(s)"] || ''} onChange={handleChange}></textarea>
                </div>
                <div className="form-group">
                    <label>Type of subject(s)</label>
                    <input type="text" name="Type of subject(s)" value={formData["Type of subject(s)"] || ''} onChange={handleChange} />
                </div>
                <div className="form-group">
                    <label>Type of account(s) misused</label>
                    <input type="text" name="Type of account(s) misused" value={formData["Type of account(s) misused"] || ''} onChange={handleChange} />
                </div>
                <div className="form-group">
                    <label>Other countries involved (explain)</label>
                    <textarea name="Other countries involved (explain)" value={formData["Other countries involved"] || ''} onChange={handleChange}></textarea>
                </div>
                <div className="form-group">
                    <label>Amount/Value involved</label>
                    <input type="text" name="Amount/Value involved" value={formData["Amount/Value involved"] || ''} onChange={handleChange} />
                </div>
                <div className="form-group">
                    <label>Does this case involve any of the following typologies? Check all that apply.</label>
                    <div>
                        <label><input type="checkbox" name="Nominee shareholder" value="yes" checked={formData["Nominee shareholder"] === 'Yes'} onChange={handleChange} /> Nominee shareholder</label>
                        <label><input type="checkbox" name="Nominee director" value="Nominee director" checked={formData["Nominee director"] === 'Yes'} onChange={handleChange} /> Nominee director</label>
                        <label><input type="checkbox" name="Corporate Director" value="Corporate Director" checked={formData["Corporate Director"] === 'Yes'} onChange={handleChange} /> Corporate Director</label>
                        <label><input type="checkbox" name="Politically exposed persons" value="Politically exposed persons" checked={formData["Politically exposed persons"] === 'Yes'} onChange={handleChange} /> Politically exposed persons</label>
                        <label><input type="checkbox" name="Link with High Risk Jurisdiction" value="Link with High Risk Jurisdiction" checked={formData["Link with High Risk Jurisdiction"] === 'Yes'} onChange={handleChange} /> Link with High Risk Jurisdiction</label>
                        <label><input type="checkbox" name="Complex structure" value="Complex structure" checked={formData["Complex structure"] === 'Yes'} onChange={handleChange} /> Complex structure</label>
                        <label><input type="checkbox" name="Shell/shelf/dormant company" value="Shell/shelf/dormant company" checked={formData["Shell/shelf/dormant company"] === 'Yes'} onChange={handleChange} /> Shell/shelf/dormant company</label>
                        <label><input type="checkbox" name="High Risk industry" value="High Risk industry" checked={formData["High Risk industry"] === 'Yes'} onChange={handleChange} /> High Risk industry</label>
                    </div>       </div>
            </form>
            <button onClick={handleDownload}>Download as DOC</button>
        </div>
    );
}

export default Form;
