
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LoginPage from './components/Login';
import Chat from './components/Chat';
import ChatHistory from './components/ChatHistory';
import SignUp from './components/SignUp';







const App = () => {
  return (
    <Router>
      <Routes>
   
        <Route path="/" element={<LoginPage />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/chat" element={<Chat />} />
        <Route path="/chathistory" element={<ChatHistory />} />
      
      
      
   
     
       
      </Routes>
    </Router>
  );
};

export default App;


