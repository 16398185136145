

import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import loaderImage from "../assets/images/loader1.gif";
import profile from "../assets/images/profile.jpg";
import { useNavigate} from "react-router-dom"; 
const ChatMessage = ({ children, avatar, name, timestamp }) => (
  
  <ChatMessageWrapper>
    <ChatMessageHeader>
      <ChatMessageAvatar>
        <img src={profile}  alt={`${name}'s avatar`} />
      </ChatMessageAvatar>
      <ChatMessageName>{name}</ChatMessageName>
      <ChatMessageTimestamp>{timestamp}</ChatMessageTimestamp>
    </ChatMessageHeader>
    <ChatMessageContent>{children}</ChatMessageContent>
  </ChatMessageWrapper>
);

const ChatResponse = ({ children,timestamp }) => (
  <ChatResponseWrapper>
    <ChatResponseHeader>
      <ChatResponseAvatar>
        <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/b05ee639c2afc02564f1a50d237625cde245f9b55fced0be428cc6cca618eeca?apiKey=a938513dc279413eaac5ce0f8c637f66&" alt="RAG's avatar" />
        <ChatResponseName>RAG</ChatResponseName>
      </ChatResponseAvatar>
      <ChatResponseTimestamp>{timestamp}</ChatResponseTimestamp>
 
    </ChatResponseHeader>
    <ChatResponseContent>{children}</ChatResponseContent>
    <ChatResponseIndicator />
  </ChatResponseWrapper>
);

export default function ChatHistory() {
    const [tasks, setTasks] = useState({});
	
	const username2 = sessionStorage.getItem("username");
	const [historyData, setHistoryData] = useState([]);
	const [loading, setLoading] = useState(false)
    const navigate = useNavigate(); 
    const storedUsername = sessionStorage.getItem("username");
   
      const username = username2.split("_")[0];
    
     

    
 
	
 
  const fetchHistoryData = async () => {
    setLoading(true);
    try {
         const apiUrl = "https://rag-bvi.radiant.digital/api";
        const response = await fetch(`${apiUrl}/api/v1/chat/submit/${username2}/streaming/recall`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            }
        });
        const data = await response.json();
        if (Array.isArray(data)) {
            setLoading(false);
            setHistoryData(data);
            console.log(data)
        } else {
            console.error("Fetched data is not an array:", data);
        }
    } catch (error) {
        setLoading(false);
        console.error("Error fetching history data:", error);
    }
};
const handleLogout = () => {
    // Clear session storage
    sessionStorage.removeItem("username");
    sessionStorage.removeItem("chatHistory");
    
    // Redirect the user to the login page or perform any other logout actions
    navigate("/");
  };

useEffect(() => {
    fetchHistoryData();
}, []); 

const renderHumanContent = () => {
    return historyData
        .filter(item => item.type === "human")
        .map((item, index) => (
            <div key={index} className="df-p-3 df-text-white" style={{
                
                "width":"100%",
                "padding":"10px",
                "height":"35px",
                "color":"black"
            }}>
                {item.content}
            </div>
        ));
};
const renderHumanContent3 = () => {
    return historyData
        .filter(item => item.type === "human")
        .map((item, index) => (
            <div key={index} className="df-p-3 df-text-white" style={{ "border-bottom":"1px solid",
                "color":"white",
              "margin-bottom":"5px",
                "padding":"5px"}} >
                {item.content}
            </div>
        ));
};
const renderHumanContent2 = () => {
    return historyData
        .filter(item => item.type === "ai")
        .map((item, index) => (
            <div key={index} className="df-p-3 df-text-white" style={{
                
                "border-radius": "5px",
                
                "padding":"25px",
                
                "width":"100%",
            
                "padding":"5px",
                "color":"black"
                
            }}>
                {item.content}
            </div>
        ));
};
const handleNaviagte=()=>{
    navigate("/chat");
}


  return (
    <Div>
    {loading && (
      <div className="loader-overlay">
          
              <img className="imageloader" src={loaderImage} alt="Loader" />
          
      </div>
      )}
      <Div2>
        <Div3>
          <Column>
            <Div4>
              <Div5>
                <Div6>
                  <Img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/a92a0e7062bdd93731a7c77fe72aec2679ae21281ad7a6ce09d5cd472881df03?apiKey=a938513dc279413eaac5ce0f8c637f66&"
                  />
                </Div6>
                <Div7>
                  <Div8>
                    <Div9>
                      <Img2
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/fd9a32a6d9d8c1356ffc219b0af125b7820360ccb876e3870a60f5ff5f74c395?apiKey=a938513dc279413eaac5ce0f8c637f66&"
                      />
                      <Div10 style={{"cursor":"pointer"}} onClick={handleNaviagte}>New Chat</Div10>
                    </Div9>
                  </Div8>
                  <Div11>
                    <Div12>
                      <Img3
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/52a59a6ef132f7f989c9ffd3394a0d583483bf347fa0032b483736dca6bf88ac?apiKey=a938513dc279413eaac5ce0f8c637f66&"
                      />
                      <Div13>Chat History</Div13>
                    </Div12>
                    <Div14>
                      <Div15>
                        <Div16>Recent Chats</Div16>
                        <Div16>   {renderHumanContent3()}</Div16>
                       
                      </Div15>
                    </Div14>
                  </Div11>
                </Div7>
              </Div5>
              <Div18>
                <Div19>
                  <Img4
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/607dc7965e53bcf944f399896655506dc0d2f6d95fd61c1df02bf14534244238?apiKey=a938513dc279413eaac5ce0f8c637f66&"
                  />
                  <Div20>Settings</Div20>
                </Div19>
                <Div19 onClick={handleLogout} style={{"cursor":"pointer"}}>
                
    
                 
    
                <img   className="" 
                              style={{"height":"24px","width":"24px"}}
                              src='assets/images/logout.png' alt="Logo" />
                
                
                                <span className="df-ms-3"
                                >
                                  Logout
                                </span>
                
                
                            </Div19>
                <Div21>
                  <Div22>
                    <Img5
                      loading="lazy"
                      src={profile} 
                    />
                    <Div23 />
                  </Div22>
                  <Div24>
                    <Div25> {username}</Div25>
                 
                  </Div24>
                </Div21>
              </Div18>
            </Div4>
          </Column>
          <Column2>
            <Div27>
              <Div28>
                <Div29>
              
                  <Div33>
                
                   
                   
                    <Div45>
                    {renderHumanContent().map((content, index,item) => (
                      <ChatContainer  key={index}>
                        <ChatMessage
                       
                          avatar="https://cdn.builder.io/api/v1/image/assets/TEMP/ff0cb8c3c76b9eb76bf2273301f838af4e222d5faf2c45f2a4b89d436c6dca09?apiKey=a938513dc279413eaac5ce0f8c637f66&"
                          name={username}
                          timestamp={item.timestamp}
                        >
                        {content}
                        </ChatMessage>
                        {renderHumanContent2()[index] && (
                        <ChatResponse timestamp={item.timestamp}>
                        {renderHumanContent2()[index]}
                        </ChatResponse>
                        )}
                      </ChatContainer>
                      ))}
                    

                  

                    </Div45>
                  </Div33>

                </Div29>
              </Div28>
            </Div27>
          </Column2>
        </Div3>
      </Div2>
    </Div>
  );
}

const Div = styled.div`
background-color: var(--secondary-color);

  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Div2 = styled.div`
  width: 100%;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div3 = styled.div`
  gap: 20px;
  display: flex;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 21%;
  margin-left: 0px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Div4 = styled.div`
 
  border: 0px solid var(--Gray-200, #eaecf0);
  background-color: var(--secondary-color);

  display: flex;
  flex-grow: 1;
  flex-direction: column;
 
`;

const Div5 = styled.div`
  padding-top: 32px;
  display: flex;
  flex-direction: column;
`;

const Div6 = styled.div`
  align-items: start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 20px 0 24px;
  @media (max-width: 991px) {
    padding-left: 20px;
  }
`;

const Img = styled.img`
  aspect-ratio: 2.94;
  object-fit: auto;
  object-position: center;
  width: 96px;
`;

const Div7 = styled.div`
  display: flex;
  margin-top: 32px;
  flex-direction: column;
  padding: 0 16px;
`;

const Div8 = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  color: var(--Primary-100, #f4ebff);
  font-weight: 600;
  line-height: 24px;
  justify-content: center;
`;

const Div9 = styled.div`
  border-radius: 25px;
  border-color: rgba(179, 186, 188, 1);
  border-style: solid;
  border-width: 1px;
  display: flex;
  gap: 8px;
  padding: 8px 16px;
`;

const Img2 = styled.img`
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 20px;
  margin: auto 0;
`;

const Div10 = styled.div`
  font-family: Inter, sans-serif;
`;

const Div11 = styled.div`
  display: flex;
  margin-top: 16px;
  flex-direction: column;
`;

const Div12 = styled.div`
  border-radius: 6px;
  display: flex;
  gap: 8px;
  font-size: 16px;
  color: var(--Primary-100, #f4ebff);
  font-weight: 600;
  line-height: 150%;
  padding: 12px;
`;

const Img3 = styled.img`
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 24px;
`;

const Div13 = styled.div`
  font-family: Inter, sans-serif;
  flex: 1;
`;

const Div14 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 24px;
  justify-content: center;
  padding: 0 23px;
  @media (max-width: 991px) {
    padding-left: 20px;
  }
`;

const Div15 = styled.div`
  padding-left: 16px;
  border-color: rgba(25, 101, 129, 1);

  border-left: 1px solid ;
  display: flex;
  flex-direction: column;
  height:400px;
  overflow: auto !important;
`;

const Div16 = styled.div`
  padding-top: 8px;
  color: #91a5ac;
  font: 500 14px Inter, sans-serif;
 

 
`;

const Div17 = styled.div`
  border-radius: 4px;
  background-color: #22799a;
  margin-top: 8px;
  color: #fff;
  justify-content: center;
  padding: 4px;
  font: 600 12px Inter, sans-serif;
`;

const Div18 = styled.div`

  background-color: var(--secondary-color);

  display: flex;



  flex-direction: column;
  padding: 16px 16px 20px;
  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;

const Div19 = styled.div`
  border-radius: 6px;
  display: flex;
  gap: 8px;
  font-size: 16px;
  color: var(--Primary-100, #f4ebff);
  font-weight: 600;
  white-space: nowrap;
  line-height: 150%;
  padding: 12px;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Img4 = styled.img`
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 24px;
`;

const Div20 = styled.div`
  font-family: Inter, sans-serif;
  flex: 1;
`;

const Div21 = styled.div`
  display: flex;
  margin-top: 16px;
  gap: 8px;
`;

const Div22 = styled.div`
  disply: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 200px;
  overflow: hidden;
  position: relative;
  display: flex;
  aspect-ratio: 1;
  width: 48px;
`;

const Img5 = styled.img`
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
`;

const Div23 = styled.div`
  position: relative;
  border-radius: 200px;
  border-color: rgba(16, 24, 40, 1);
  border-style: solid;
  border-width: 1px;
  height: 48px;
`;

const Div24 = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  line-height: 143%;
  margin: auto 0;
`;

const Div25 = styled.div`
  color: var(--Base-White, var(--Base-White, #fff));
  font-family: Inter, sans-serif;
  font-weight: 600;
`;

const Div26 = styled.div`
  color: var(--Primary-200, var(--Primary-200, #e9d7fe));
  font-family: Inter, sans-serif;
  font-weight: 400;
`;

const Column2 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 79%;
  margin-left: 20px;
  @media (max-width: 991px) {
    width: 100%;
    height:100vh;
    overflow:scroll;
  
  }
`;

const Div27 = styled.div`
  padding-top: 16px;
  justify-content: flex-end;
  align-self: stretch;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  @media (max-width: 991px) {
    max-width: 100%;
    
  }
`;

const Div28 = styled.div`
  justify-content: space-between;
  align-items: center;
  border-radius: 24px 0px 0px 0px;
  background-color: #f6faff;
  display: flex;
  flex-direction: column;
  padding: 50px 60px 3px;
  @media (max-width: 991px) {
    max-width: 100%;
    padding: 0 20px;
  }
`;

const Div29 = styled.div`
  display: flex;
  width: 664px;
  max-width: 100%;
  flex-direction: column;
`;

const Div30 = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Img6 = styled.img`
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 108px;
  max-width: 100%;
`;

const Div31 = styled.div`
  color: var(--Gray-900, var(--Gray-900, #101828));
  margin-top: 18px;
  font: 600 30px/127% Inter, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div32 = styled.div`
  align-self: stretch;
  color: var(--Gray-600, var(--Gray-600, #475467));
  margin-top: 18px;
  font: 400 16px/24px Inter, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div33 = styled.div`
  display: flex;
 
  flex-direction: column;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
`;

const Div34 = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  line-height: 24px;
  justify-content: center;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div35 = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div36 = styled.div`
  border-radius: 16px;
  border-color: rgba(227, 227, 227, 1);
  border-style: solid;
  border-width: 1px;
  background-color: #fcfcfc;
  display: flex;
  flex-direction: column;
  padding: 8px 16px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div37 = styled.div`
  color: #101828;
  font-family: Inter, sans-serif;
  font-weight: 600;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div38 = styled.div`
  color: #54617b;
  font-family: Inter, sans-serif;
  font-weight: 500;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div39 = styled.div`
  border-radius: 16px;
  border-color: rgba(227, 227, 227, 1);
  border-style: solid;
  border-width: 1px;
  background-color: #fcfcfc;
  display: flex;
  margin-top: 16px;
  flex-direction: column;
  padding: 8px 16px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div40 = styled.div`
  color: #101828;
  font-family: Inter, sans-serif;
  font-weight: 600;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div41 = styled.div`
  color: #54617b;
  font-family: Inter, sans-serif;
  font-weight: 500;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div42 = styled.div`
  border-radius: 16px;
  border-color: rgba(227, 227, 227, 1);
  border-style: solid;
  border-width: 1px;
  background-color: #fcfcfc;
  display: flex;
  margin-top: 16px;
  flex-direction: column;
  padding: 8px 16px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div43 = styled.div`
  color: #101828;
  font-family: Inter, sans-serif;
  font-weight: 600;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div44 = styled.div`
  color: #54617b;
  font-family: Inter, sans-serif;
  font-weight: 500;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div45 = styled.div`
  border-radius: 36px;
  box-shadow: -4px 3px 11px 0px rgba(3, 46, 63, 0.07);
  border-color: rgba(205, 223, 230, 1);
  border-style: solid;
  border-width: 1px;
  background-color: #fff;
  display: flex;
  margin-top: 29px;
  flex-direction: column;
  font-size: 16px;
  color: #8d8d8d;
  font-weight: 400;
  line-height: 150%;
  justify-content: center;
  padding: 10px 10px 10px 16px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div46 = styled.div`
  justify-content: space-between;
  display: flex;
  gap: 20px;
  @media (max-width: 991px) {
    max-width: 100%;
    flex-wrap: wrap;
  }
`;

const Div47 = styled.div`
  font-family: Inter, sans-serif;
  margin: auto 0;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Img7 = styled.img`
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 44px;
  fill: linear-gradient(97deg, #09455c 13.13%, #1385b0 149.17%);
`;

const Div48 = styled.div`
  color: var(--Grey-Grey-6, #000);
  text-align: center;
  -webkit-text-stroke-width: 2;
  -webkit-text-stroke-color: var(--Gray-gray-1, #fff);
  border-color: rgba(255, 255, 255, 1);
  border-style: solid;
  border-width: 2px;
  align-self: start;
  margin: 14px 0 0 68px;
  font: 450 10px/110% Neue Haas Grotesk Display Std, -apple-system, Roboto,
    Helvetica, sans-serif;
  @media (max-width: 991px) {
    margin-left: 10px;
  }
`;
const ChatInput = styled.input`
  flex: 1;
  border: none;
  outline: none;
  padding: 10px;
  border-radius: 20px;
`;
const ChatInputWrapper = styled.div`
  display: flex;
  gap: 5px;
  justify-content: space-between;
  align-items: center;
`;
const ChatInputSection = styled.div`
  background-color: #fff;
  border: 1px solid rgba(205, 223, 230, 1);
  border-radius: 36px;
}
`;
const SendIcon = styled.img`
  width: 30px;
  cursor: pointer;
`;
const ChatContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 664px;
`;

const ChatMessageWrapper = styled.div`
  background-color: #ebf3fd;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 16px;
  padding: 16px 40px;

  @media (max-width: 991px) {
    max-width: 100%;
    padding: 16px 20px;
  }
`;

const ChatMessageHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 16px;

  @media (max-width: 991px) {
    flex-wrap: wrap;
  }
`;

const ChatMessageAvatar = styled.div`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;

  img {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
`;

const ChatMessageName = styled.div`
  color: #101828;
  font: 400 14px/24px Inter, sans-serif;
`;

const ChatMessageTimestamp = styled.div`
  color: #575b63;
  font: 400 14px/24px Inter, sans-serif;
`;

const ChatMessageContent = styled.div`
  color: #101828;
  font: 500 16px/24px Inter, sans-serif;
`;

const ChatResponseWrapper = styled.div`
  background-color: #fff;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 16px 40px;

  @media (max-width: 991px) {
    max-width: 100%;
    padding: 16px 20px;
  }
`;

const ChatResponseHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 24px;

  @media (max-width: 991px) {
    flex-wrap: wrap;
  }
`;

const ChatResponseAvatar = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  img {
    width: 25px;
    height: 25px;
    object-fit: cover;
    object-position: center;
  }
`;

const ChatResponseName = styled.div`
  color: #101828;
  font-family: Inter, sans-serif;
`;

const ChatResponseTimestamp = styled.div`
  color: #575b63;
  font-family: Inter, sans-serif;
`;

const ChatResponseContent = styled.div`
  color: #101828;
  font: 400 14px/24px Inter, sans-serif;
`;

const ChatResponseIndicator = styled.div`
  width: 34px;
  height: 6px;
  margin-top: 24px;
`;

